<template>
  <div>
    <vx-card actionable class="cardx" title="Whatsapp Messages">
      <vs-row>
        <vs-col
          v-for="(name, i) in courses"
          :key="i"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
          ><vs-radio
            v-model="whatsapp_courses"
            :vs-value="name.id"
            @change="changeCourse(name)"
            >{{ name.course }}</vs-radio
          ></vs-col
        > </vs-row
      ><br />
      <vs-row style="margin-bottom: 2%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
          <p>
            To send text in <b>bold</b>, place an astrisk on both sides of the text. like
            so: *USA CPA Template*
          </p>
        </vs-col>
      </vs-row>
      <div v-for="(template, index) in message" :key="index" style="margin-bottom: 2%">
        <vs-row>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12" style="height: 49vh;margin-top: 3%;">
            <vs-textarea
              id="connectedWhatsapp"
              rows="3"
              :disabled="!template.connected_editing"
              :label="`Connected - ${template.visibile_name}`"
              v-model="template.connected_message"
            ></vs-textarea>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12" style="height: 49vh;">
              <vs-textarea
                id="notConnectedWhatsapp"
                rows="3"
                :disabled="!template.not_connected_editing"
                :label="`Not Connected - ${template.visibile_name}`"
                v-model="template.not_connected_message"
              ></vs-textarea>
            </vs-col>
          </vs-col>
        </vs-row>
        <vs-row style="margin-top: 5%;">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
            <vs-button
              :color="template.buttonColor"
              type="border"
              style="margin-right: 1%"
              @click="connectedchangeState(template)"
              >{{ template.connected_editName }}</vs-button
            >
            <vs-button
              color="success"
              type="border"
              @click="SaveTemplate(template, 'connected')"
              >Save</vs-button
            >
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
            <vs-button
              :color="template.buttonColor"
              type="border"
              style="margin-right: 1%"
              @click="notconnectedchangeState(template)"
              >{{ template.not_connected_editName }}</vs-button
            >
            <vs-button
              color="success"
              type="border"
              @click="SaveTemplate(template, 'not_connected')"
              >Save</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vx-card>
  </div>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";
export default {
  mounted() {
    this.fetchAllMessages();
  },
  data() {
    return {
      whatsapp_courses: "",
      selectedCourse_id: null,
      selected_status: "",
      message: [],
      // courses: [
      //   {
      //     id: 1,
      //     course: "CPA",
      //     visibile_name: "CPA Game Plan template",
      //     connected_message: "",
      //     not_connected_message: "",
      //     connected_editing: false,
      //     not_connected_editing: false,
      //     connected_editName: "Edit",
      //     not_connected_editName: "Edit",
      //     buttonColor: "primary",
      //     originalTemplate: "",
      //   },
      //   {
      //     id: 2,
      //     course: "CMA",
      //     connected_message: "",
      //     not_connected_message: "",
      //     visibile_name: "CMA Game Plan template",
      //     connected_editing: false,
      //     not_connected_editing: false,
      //     connected_editName: "Edit",
      //     not_connected_editName: "Edit",
      //     buttonColor: "primary",
      //     originalTemplate: "",
      //   },
      //   {
      //     id: 3,
      //     course: "CPA&CMA",
      //     visibile_name: "CMA Webinar template",
      //     connected_message: "",
      //     not_connected_message: "",
      //     connected_editing: false,
      //     not_connected_editing: false,
      //     connected_editName: "Edit",
      //     not_connected_editName: "Edit",
      //     buttonColor: "primary",
      //     originalTemplate: "",
      //   },
      //   // {
      //   //   id:4,
      //   //   course: "CPA-Message-2",
      //   //   visibile_name: "CPA Webinar template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id: 5,
      //   //   course: "IIML-FA",
      //   //   visibile_name: "IIML-FA template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id: 6,
      //   //   course: "IIML-SF",
      //   //   visibile_name: "IIML-SF template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id:7,
      //   //   course: "IIML-BA",
      //   //   visibile_name: "IIML-BA template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id: 8,
      //   //   course: "IIML-PM",
      //   //   visibile_name: "IIML-PM template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id:9,
      //   //   course: "IIML-HR",
      //   //   visibile_name: "IIML-HR template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },

      //   // {
      //   //   id: 11,
      //   //   course: "IITR-DB",
      //   //   visibile_name: "IITR-DB template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id: 12,
      //   //   course: "IITM-AA",
      //   //   visibile_name: "IITM-AA template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id: 10,
      //   //   course: "IITR-BF",
      //   //   visibile_name: "IITR-BF template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id: 13,
      //   //   course: "IIMK-CX",
      //   //   visibile_name: "IIMK-CX template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id: 14,
      //   //   course: "IITM-FS",
      //   //   visibile_name: "IITM-FS template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id: 15,
      //   //   course: "IITR-CC",
      //   //   visibile_name: "IITR-CC template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id: 16,
      //   //   course: "IIMK-FT",
      //   //   visibile_name: "IIMK-FT template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id: 17,
      //   //   course: "IIML-AB",
      //   //   visibile_name: "IIML-AB template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id: 18,
      //   //   course: "IIML-SH",
      //   //   visibile_name: "IIML-SH template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   {
      //     id: 19,
      //     course: "CPA-AA",
      //     visibile_name: "CPA-AA template",
      //     connected_message: "",
      //     not_connected_message: "",
      //     connected_editing: false,
      //     not_connected_editing: false,
      //     connected_editName: "Edit",
      //     not_connected_editName: "Edit",
      //     buttonColor: "primary",
      //     originalTemplate: "",
      //   },
      //   // {
      //   //   id: 20,
      //   //   course: "CIA",
      //   //   visibile_name: "CIA template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id: 21,
      //   //   course: "CISA",
      //   //   visibile_name: "CISA template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id: 22,
      //   //   course: "EA",
      //   //   visibile_name: "EA template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   // {
      //   //   id: 23,
      //   //   course: "IITJ-DE",
      //   //   visibile_name: "IITJ-DE template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   {
      //     id: 24,
      //     course: "XLRI-HR",
      //     visibile_name: "XLRI-HR template",
      //     connected_message: "",
      //     not_connected_message: "",
      //     connected_editing: false,
      //     not_connected_editing: false,
      //     connected_editName: "Edit",
      //     not_connected_editName: "Edit",
      //     buttonColor: "primary",
      //     originalTemplate: "",
      //   },
      //   // {
      //   //   id: 25,
      //   //   course: "XLRI-DM",
      //   //   visibile_name: "XLRI-DM template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   {
      //     id: 26,
      //     course: "CFA",
      //     visibile_name: "CFA template",
      //     connected_message: "",
      //     not_connected_message: "",
      //     connected_editing: false,
      //     not_connected_editing: false,
      //     connected_editName: "Edit",
      //     not_connected_editName: "Edit",
      //     buttonColor: "primary",
      //     originalTemplate: "",
      //   },
      //   {
      //     id: 27,
      //     course: "MAcc",
      //     visibile_name: "MAcc template",
      //     connected_message: "",
      //     not_connected_message: "",
      //     connected_editing: false,
      //     not_connected_editing: false,
      //     connected_editName: "Edit",
      //     not_connected_editName: "Edit",
      //     buttonColor: "primary",
      //     originalTemplate: "",
      //   },
      //   {
      //     id: 28,
      //     course: "FRM",
      //     visibile_name: "FRM template",
      //     connected_message: "",
      //     not_connected_message: "",
      //     connected_editing: false,
      //     not_connected_editing: false,
      //     connected_editName: "Edit",
      //     not_connected_editName: "Edit",
      //     buttonColor: "primary",
      //     originalTemplate: "",
      //   },
      //   // {
      //   //   id: 29,
      //   //   course: "IIMI-YLP",
      //   //   visibile_name: "IIMI-YLP template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   {
      //     id: 30,
      //     course: "IIML-FT",
      //     visibile_name: "IIML-FT template",
      //     connected_message: "",
      //     not_connected_message: "",
      //     connected_editing: false,
      //     not_connected_editing: false,
      //     connected_editName: "Edit",
      //     not_connected_editName: "Edit",
      //     buttonColor: "primary",
      //     originalTemplate: "",
      //   },
      //   // {
      //   //   id: 31,
      //   //   course: "RPO",
      //   //   visibile_name: "RPO template",
      //   //   connected_message: "",
      //   //   not_connected_message: "",
      //   //   connected_editing: false,
      //   //   not_connected_editing: false,
      //   //   connected_editName: "Edit",
      //   //   not_connected_editName: "Edit",
      //   //   buttonColor: "primary",
      //   //   originalTemplate: "",
      //   // },
      //   {
      //     id: 32,
      //     course: "IIMI-BA",
      //     visibile_name: "IIMI-BA template",
      //     connected_message: "",
      //     not_connected_message: "",
      //     connected_editing: false,
      //     not_connected_editing: false,
      //     connected_editName: "Edit",
      //     not_connected_editName: "Edit",
      //     buttonColor: "primary",
      //     originalTemplate: "",
      //   },
      //   {
      //     id: 33,
      //     course: "AICPA",
      //     visibile_name: "AICPA template",
      //     connected_message: "",
      //     not_connected_message: "",
      //     connected_editing: false,
      //     not_connected_editing: false,
      //     connected_editName: "Edit",
      //     not_connected_editName: "Edit",
      //     buttonColor: "primary",
      //     originalTemplate: "",
      //   },
      // ],
      courses : []
    };
  },
  watch: {
    whatsapp_courses(value) {
      this.selectedCourse_id = value;
    },
  },
  methods: {
    changeCourse(val) {
      this.message = [];
      let obj = {
        course: val.course,
        visibile_name: val.visibile_name,
        connected_message: val.connected_message,
        not_connected_message: val.not_connected_message,
        connected_editing: val.connected_editing,
        not_connected_editing: val.not_connected_editing,
        connected_editName: val.connected_editName,
        not_connected_editName: val.not_connected_editName,
        buttonColor: val.buttonColor,
        originalTemplate: val.originalTemplate,
      };
      this.message.push(obj);
    },
    fetchAllMessages() {
      this.$vs.loading();
      axios
        .get(`${constants.SERVER_API}getWhatsAppMessages`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          if (response.data.status !== "success") {
            this.handleNotification(response);
          }
          // response.data.whats_app_messages.forEach((message) => {
          //   this.courses.forEach((template) => {
          //     if (message.course === template.course) {
          //       template.originalTemplate = message.message;
          //       template.connected_message = message.connected_message;
          //       template.not_connected_message = message.not_connected_message;
          //     }
          //   });
          // });
          this.courses = [];
          response.data.whats_app_messages.forEach((message) => {
            let obj = {
              id: message.id,
              course: message.course,
              visibile_name: message.course + " template",
              connected_message:  message.connected_message,
              not_connected_message: message.not_connected_message,
              connected_editing: false,
              not_connected_editing: false,
              connected_editName: "Edit",
              not_connected_editName: "Edit",
              buttonColor: "primary",
              originalTemplate: message.message,
            }
            this.courses.push(obj)
          });
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    connectedchangeState(template) {
      this.selected_status = "connected";
      template.not_connected_editing = false;
      template.connected_editing = !template.connected_editing;
      if (template.connected_editing) {
        template.connected_editName = "Cancel";
        template.buttonColor = "danger";
        template.not_connected_editName = "Edit";
        template.buttonColor = "primary";
      } else {
        template.message = template.originalTemplate;
        template.connected_editName = "Edit";
        template.buttonColor = "primary";
      }
    },
    notconnectedchangeState(template) {
      this.selected_status = "not_connected";
      template.connected_editing = false;
      template.not_connected_editing = !template.not_connected_editing;
      if (template.not_connected_editing) {
        template.not_connected_editName = "Cancel";
        template.buttonColor = "danger";
        template.connected_editName = "Edit";
        template.buttonColor = "primary";
      } else {
        template.message = template.originalTemplate;
        template.not_connected_editName = "Edit";
        template.buttonColor = "primary";
      }
    },
    SaveTemplate(template, saveStatus) {
      console.log("template Data", saveStatus);
      let obj = {};
      if (saveStatus === "connected") {
        obj = {
          course_id: this.selectedCourse_id,
          course: template.course,
          message: template.connected_message,
          status: this.selected_status,
        };
      } else {
        obj = {
          course_id: this.selectedCourse_id,
          course: template.course,
          message: template.not_connected_message,
          status: this.selected_status,
        };
      }
      console.log(obj);
      // let url = `${constants.SERVER_API}addWhatsAppMessage`;
      let url = `${constants.MILES_CM_BACK}updateWhatsAppConnectedMessages`;
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.fetchAllMessages();
            template.connected_editing = false;
            template.not_connected_editing = false;
            template.connected_editName = "Edit";
            template.not_connected_editName = "Edit";
            template.buttonColor = "primary";
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style>
#connectedWhatsapp {
    height: 344px;
    font-size: 10px;
}
#notConnectedWhatsapp {
    height: 344px;
    font-size: 10px;
    /* margin-bottom: 10%; */
}
</style>
